import React from 'react'
import Nav from './Nav'
import ImgSlider from '../SubPages/ImgSlider'
export default function Pilersnosepiler() {
  return (
    <div>
       <div>
      <Nav/>
      <ImgSlider/>
    </div>
    </div>
  )
}
