export const Products = [
  {
    id: 1,
    modelno: "MT02 Dx PLUS",
    name: "2 in 1 Wire Stripper & Cutter",
    img: "02DX PLUS-02.webp",
    headname: "Wire Stripper",
    category: "Wire Stripper",
    OVERALLLENGTHTitle: "Overall length: ",
    OVERALLLENGTHT: "180mm.",
    CapacityTitle: "Capacity:",
    capacity: " Cu Wire dia 14-24 AWG / 0.5mm² - 6mm².",
    URL: "wirestriperbanner.webp",
    URLname: "MT02-Dx_PLUS",
    material: "Plastic.",
    handeltitle: "Handle:",
    handel: "Nylon.",
    ApplicatonTitle: "Application: ",
    Applicaton:
      "Strip and cut wires/cables accurately on both solid core & stranded wires.",
    features:
      "1. New improved engineering reinforced plastic material enhanced performance.",
    features1:
      "2. Suitable for use in tight and narrow spaces with stripping Length capacity up to 20mm.",
  },
  {
    id: 2,
    modelno: "02dx",
    name: "Wire Stripper",
    img: "02dx.webp",
    headname: "Wire Stripper",
    category: "Wire Stripper",
    OVERALLLENGTHTitle: "Overall length: ",
    OVERALLLENGTHT: "180mm.",
    CapacityTitle: "Capacity:",
    capacity: " Cu Wire dia 14-24 AWG / 0.5mm² - 7mm².",
    URL: "wirestriperbanner.webp",
    URLname: "02dx",
    material: "Plastic.",
    handeltitle: "Handle:",
    handel: "Nylon.",
    ApplicatonTitle: "Application: ",
    Applicaton:
      " A unique tool to strip and cut insulation on copper wire ranging from 0.5mm to 2.5mm or 6mm diameter.",
    features:
      "Suitable for electronics, aviation, automobile, and general maintenance.",
  },
  {
    id: 3,
    modelno: "150B",
    name: "Wire Stripper & Cutter",
    img: "150B.webp",
    headname: "Wire Stripper",
    category: "Wire Stripper",
    OVERALLLENGTHTitle: "Overall length: ",
    OVERALLLENGTHT: "140mm.",
    CapacityTitle: "Capacity:",
    capacity: "Cu Wire upto 4.0mm².",
    URL: "wirestriperbanner.webp",
    URLname: "150B",
    material: "High grade tempered Steel.",
    handeltitle: "Handle:",
    handel: "PVC Cushioned grips.",
    ApplicatonTitle: "Application: ",
    Applicaton: "Suitable to cut & strip electrical wires.",
    features: "With screw gauge adjuster.",
  },
  {
    id: 4,
    modelno: "150C Champion",
    name: "Heavy Duty Wire Stripper & Cutter",
    img: "150C CHAMPION.webp",
    headname: "Wire Stripper",
    category: "Wire Stripper",
    OVERALLLENGTHTitle: "Overall length: ",
    OVERALLLENGTHT: "140mm.",
    CapacityTitle: "Capacity:",
    capacity: "Cu Wire upto 4.0mm².",
    URL: "wirestriperbanner.webp",
    URLname: "150C_Champion",
    material: "PVC Cushioned grips.",
    handeltitle: "Handle:",
    handel: "Acetate with Extra hand grip.",
    ApplicatonTitle: "Application: ",
    Applicaton: "Suitable to cut & strip electrical wires.",
    features: "1. Wire stripper and cutter with a heavy-duty long handle.",
    features1: "2. Heavy-duty construction with precision cutting edges.",
  },
  {
    id: 5,
    modelno: "68C",
    name: "Wire Stripper & Cutter",
    img: "68C.webp",
    headname: "Wire Stripper",
    category: "Wire Stripper",
    OVERALLLENGTHTitle: "Overall length: ",
    OVERALLLENGTHT: "140mm.",
    CapacityTitle: "Capacity:",
    capacity: "Cu Wire upto 4.0mm².",
    URL: "wirestriperbanner.webp",
    URLname: "68_C",
    material: "High grade tempered Steel.",
    handeltitle: "Handle:",
    handel: "PVC Cushioned grips.",
    ApplicatonTitle: "Application: ",
    Applicaton: "Suitable to cut & strip electrical wires.",
    features:
      "Wire stripper and cutter with a spring and screw gauge adjuster.",
  },
  {
    id: 6,
    modelno: "68D",
    name: "Wire Stripper & Cutter",
    img: "68D.webp",
    headname: "Wire Stripper",
    category: "Wire Stripper",
    OVERALLLENGTHTitle: "Overall length: ",
    OVERALLLENGTHT: "140mm.",
    CapacityTitle: "Capacity:",
    capacity: "Cu Wire upto 4.0mm².",
    URL: "wirestriperbanner.webp",
    URLname: "68_D",
    material: "High grade tempered Steel.",
    handeltitle: "Handle:",
    handel: "PVC Cushioned grips.",
    ApplicatonTitle: "Application: ",
    Applicaton: " Suitable to cut & strip electrical wires.",
    features:
      "1. Wire stripper and cutter with a spring and screw gauge adjuster.",
    features1: "2.  Duly grounded mirrio finish blades.",
  },

  //   screwdriver

  {
    id: 10,
    name: "2-in-1 Reversible Screwdriver",
    Series: "R 6000 ",
    img: "R 6100.webp",
    headname: "Screw Driver",
    material: "High Grade Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "CA.",
    detailstitle: "Details",
    modelnoTitle: "Model No",
    tipsizetitle: "Tip Size",
    shanksizetitle: "Shank Size",
    shanklengthTitle: "Shank Length",
    mn1: "R 6100 ",
    mn2: "R 6150",
    mn3: "R 6200 ",
    mn4: "R 6250",
    mn5: "R 6300",
    s1: "PH2/S6",
    s2: "PH2/S6",
    s3: "PH2/S6",
    s4: "PH2/S6",
    s5: "PH2/S6",
    ss1: "6.0MM HEXAGON",
    ss2: "6.0MM HEXAGON",
    ss3: "6.0MM HEXAGON",
    ss4: "6.0MM HEXAGON",
    ss5: "6.0MM HEXAGON",
    sl1: "100MM ",
    sl2: "150MM ",
    sl3: "200MM ",
    sl4: "250MM ",
    sl5: "300MM ",
    URL: "screwdriverbanner.webp",
    URLname: "R-6000",
    category: "Screw Driver",
    finish: "Chrome Plated. Brown Oxide coated.",
    finishTitle: "Finish: ",
    features: "1. 2-in-1 screwdriver: Philips end PH2 and flat end 6mm.",
    features1: "2. Made of hexagon high-grade alloy steel.",
    features2: "3. Duly hardened and tempered.",
    features3:
      "4. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
  },
  {
    id: 11,
    name: " Insulated 2-in-1 Reversible Screwdriver",
    img: "R 6100 i.webp",
    headname: "Screw Driver",
    material: "High Grade Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "CA.",
    detailstitle: "Details",
    modelnoTitle: "Model No",
    tipsizetitle: "Tip Size",
    shanksizetitle: "Shank Size",
    shanklengthTitle: "Shank Length",
    mn1: "R 6100 i ",
    mn2: "R 6150 i",
    mn3: "R 6250  i",
    s1: "PH2/S6",
    s2: "PH2/S6",
    s3: "PH2/S6",
    ss1: "6.0MM HEXAGON",
    ss2: "6.0MM HEXAGON",
    ss3: "6.0MM HEXAGON",
    sl1: "100MM ",
    sl2: "150MM ",
    sl3: "200MM ",
    URL: "screwdriverbanner.webp",
    URLname: "R_6000-i",
    category: "Screw Driver",
    finish: "Chrome Plated. Brown Oxide coated.",
    finishTitle: "Finish: ",
    Series: "R 6000 i",
    features: "1. 2-in-1 screwdriver Philips end PH2 and flat end 6mm.",
    features1: "2. Made of hexagon high-grade alloy steel.",
    features2: "3.Duly hardened and tempered.",
    features3: "4.Insulated for electrical use.",
    features4:
      "5. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
  },
  {
    id: 12,
    img: "TR 6150.webp",
    name: "2-in-1 Reversible T-Handle Screwdriver",
    Series: "TR 6000",
    headname: "Screw Driver",
    material: "High Grade Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "CA.",
    detailstitle: "Details",
    modelnoTitle: "Model No",
    tipsizetitle: "Tip Size",
    shanksizetitle: "Shank Size",
    shanklengthTitle: "Shank Length",
    mn1: "TR 6150  ",
    mn2: "TR 6250",
    s1: "PH2/S6",
    s2: "PH2/S6",
    ss1: "6.0MM HEXAGON",
    ss2: "6.0MM HEXAGON",
    sl1: "150MM ",
    sl2: "250MM ",
    URL: "screwdriverbanner.webp",
    URLname: "TR-6000",
    category: "Screw Driver",
    finishTitle: "Finish: ",
    finish: "Finish: Chrome P lated, black tipped shank.",
    features: "1.2-in-1 screwdriver: Philips end PH2 and flat end 6mm.",
    features1: "2.Made of hexagon high-grade alloy steel.",
    features2: "3.Duly hardened and tempered.",
    features3:
      "4.T-driver handle configuration allows higher torque application with minimal effort.",
    features4:
      "5.Available in plain chrome plated finish and also color-coded three-tone finish.",
    features5:
      "6. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
  },

  {
    id: 17,
    name: "4-in-1 (Tee-Driver) Reversible Screwdriver",
    Series: "TRB 6000",
    headname: "Screw Driver",
    material: "High Grade Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "CA.",
    detailstitle: "Details",
    modelnoTitle: "Model No",
    tipsizetitle: "Tip Size",
    shanksizetitle: "Shank Size",
    shanklengthTitle: "Shank Length",
    mn1: "TRB 6150",
    mn2: "TRB 6250",
    s1: "PH2/S5",
    s2: "PH2/S5",
    ss1: "6.0MM HEXAGON",
    ss2: "6.0MM HEXAGON",
    sl1: "200 MM",
    sl2: "300 MM",
    img: "TRB 6150.webp",
    URL: "screwdriverbanner.webp",
    URLname: "TRB-6000",
    category: "Screw Driver",
    finish: "Finish: ",
    finishTitle: "Chrome Plated, black tipped shank",
    features:
      "1. Unique compact design, the only one in the world to offer tee-orientation in a stubby screwdriver.",
    features1:
      "2.Four-head screwdriver in one tool, offering better value for money spent.",
    features2:
      "3.Two, two-way reversible screwdriver - one fixed and one reversible.",
    features3:
      "4. Tee-orientation offers better leverage and requires less effort while working.",
    features4: "5. 2-in-1 screwdriver: Philips end PH2 and flat end 6mm.",
    features5: "6. Made of hexagon high-grade alloy steel.",
    features6: "7. Duly hardened and tempered.",
    features7:
      "8. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
  },
  {
    id: 18,
    name: "2-in-1 Reversible Screwdriver",
    Series: "R 5000 ",
    headname: "Screw Driver",
    material: "High Grade Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "CA.",
    detailstitle: "Details",
    modelnoTitle: "Model No",
    tipsizetitle: "Tip Size",
    shanksizetitle: "Shank Size",
    shanklengthTitle: "Shank Length",
    mn1: "R 5100",
    mn2: "R 5150",
    s1: "PH1/S5     ",
    s2: "PH1/S5     ",
    ss1: "5.0MM HEXAGON",
    ss2: "5.0MM HEXAGON",
    sl1: "100 MM",
    sl2: "150 MM",
    img: "R 5100.webp",
    URL: "screwdriverbanner.webp",
    URLname: "R-5000",
    category: "Screw Driver",
    finish: "Chrome Plated. Brown Oxide coated.",
    finishTitle: "Finish: ",
    features: "1.2-in-1 screwdriver: Philips end PH1 and flat end 5mm.",
    features1: "2.Made of hexagon high-grade alloy steel.",
    features2: "3.Duly hardened and tempered.",
    features3:
      "4. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
  },
  {
    id: 19,
    name: " 2-in-1 Reversible Screwdriver",
    Series: "R 4000",
    headname: "Screw Driver",
    material: "High Grade Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "CA.",
    detailstitle: "Details",
    modelnoTitle: "Model No",
    tipsizetitle: "Tip Size",
    shanksizetitle: "Shank Size",
    shanklengthTitle: "Shank Length",
    mn1: "R 4075",
    s1: "PH0/S3",
    ss1: "54.0MM HEXAGON",
    sl1: "75 MM ",
    img: "R 4075.webp",
    URL: "screwdriverbanner.webp",
    URLname: "R-4000",
    category: "Screw Driver",
    finish: "Chrome Plated. Brown Oxide coated.",
    finishTitle: "Finish: ",
    features: "1.2 IN ONE SCREW DRIVER: PHILIP END PH-0 & FLAT END 4mm.",
    features1: "2.Made of hexagon high-grade alloy steel.",
    features2: "3.Duly hardened and tempered.",
    features3:
      "4. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
  },
  {
    id: 20,
    name: "TST Series ",
    Series: "R 6000 TST",
    headname: "Screw Driver",
    material: "High Grade Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "CA.",
    detailstitle: "Details",
    modelnoTitle: "Model No",
    tipsizetitle: "Tip Size",
    shanksizetitle: "Shank Size",
    shanklengthTitle: "Shank Length",
    mn1: "R 6100 TST",
    mn2: "R 6150 TST",
    mn3: "R 6250 TST",
    s1: "PPH2/S6",
    s2: "PPH2/S6",
    s3: "PPH2/S6",
    ss1: "54.0MM HEXAGON",
    ss2: "54.0MM HEXAGON",
    ss3: "54.0MM HEXAGON",
    sl1: "100 MM ",
    sl2: "150 MM ",
    sl3: "250 MM ",
    img: "R 6100 TST.webp",
    URL: "screwdriverbanner.webp",
    URLname: "R-6000-TST",
    category: "Screw Driver",
    finish: "Chrome Plated. Brown Oxide coated.",
    finishTitle: "Finish: ",
    features: "1.2-in-1 screwdriver Blade: Philips end PH-2 and flat end 6mm.",
    features1: "2.Made of hexagon high-grade alloy steel.",
    features2: "3.Duly hardened and tempered.",
    features3: "4.Handle made of angel-blue color for better contrast.",
    features4:
      "5.Screwdriver handle with neon bulb for voltage detection up to 500 volts.",
    features5: "6.Insulated for safety against electrical shocks.",
    features6:
      "7. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
  },

  {
    id: 36,
    modelno: "PSA06",
    URLname: "PSA06",
    // name: "Anvil Pruning Shear / Secateur",
    img: "PSA06.webp",
    headname: "Anvil Pruning Shear / Secateur",
    sizetitle: "Size: ",
    size: "Length 8.1/4  210mm.",
    CapacityTitle: "Capacity: ",
    capacity: "Cutting Capacity 25mm.",
    material: "Stainless Steel Blade.",
    handeltitle: "Handle: ",
    ApplicatonTitle: "Application: ",
    Applicaton: "Versatile tool to cut and prune tree branches.",
    handel: "CA.",
    cautionTitle: "Caution: ",
    caution: "1.Do not cut live Wires.",
    caution1: "2.Blades are sharp - keep out of children.",
    maintenanceTitle: "Maintenance: ",
    maintenance: "1.Keep blades clean.",
    maintenance1: "2.Swipe Blade with oil and apply oil in joints after use.",
    maintenance2: "3.Store in a dry place.",
    URL: "Collase of Garden tools.webp",
    category: "Garden & Agro Tools",
    features: "1.Sturdy tool construction for long life.",
    features1: "2.Ergonomic sleeves for fatigue-free continuous use.",
    features2: "3.Easy locking mechanism when not in use.",
  },

  {
    id: 41,
    modelno: "PSB09",
    URLname: "PSB09",
    // name: "Bypass Pruning Shear / Secateur",
    img: "PSB09.webp",
    headname: "Bypass Pruning Shear / Secateur",
    sizetitle: "Size: ",
    size: "Length 190mm.",
    CapacityTitle: "Capacity: ",
    capacity: "Cutting Capacity 15mm.",
    material: "Stainless Steel Blade.",
    handeltitle: "Handle: ",
    handel: "Acetate.",
    ApplicatonTitle: "Application: ",
    Applicaton: "Versatile tool to cut and prune tree branches.",
    cautionTitle: "Caution: ",
    caution: "1.Sharp blades - keep out of children.",
    caution1: "2.Do not cut live wiress.",
    maintenanceTitle: "Maintenance: ",
    maintenance: "1.Keep blades clean.",
    maintenance1: "2.Swipe with oil after use.",
    maintenance2: "3.Store in dry place.",
    URL: "Collase of Garden tools.webp",
    category: "Garden & Agro Tools",
    features1:
      "1.Ultra-sharp Stainless Steel Blades ensure efficient cutting performance.",
    features2: "2.Handles designed to fit palm bends for comfortable use.",
    features3: "3.Lightweight Plastic handles for ease of handling.",
    features4: "4.Easy locking mechanism when not in use.",
  },
  {
    id: 42,
    modelno: "AHS10",
    URLname: "Aluminium-Handle-Hedge-Shear-AHS10",
    // name: "Aluminium Handle Hedge Shear",
    img: "AHS-10.webp",
    headname: "Aluminium Handle Hedge Shear",
    sizetitle: "Size: ",
    size: "Blade Length 10.",
    // CapacityTitle: "Capacity: ",
    // capacity: "",
    material: "Alloy Steel blade.",
    handeltitle: "Handle: ",
    handel: "Aluminium Alloy Handles With Soft Grips.",
    ApplicatonTitle: "Application: ",
    Applicaton: "Trimming of hedges & bushes.",
    cautionTitle: "Caution: ",
    caution: "Sharp blades - keep out of children.",
    maintenanceTitle: "Maintenance: ",
    maintenance: "Clean blades and swipe with oil after use.",
    URL: "Collase of Garden tools.webp",
    category: "Garden & Agro Tools",
    features: "1.Blades are duly hardened & tempered.",
    features1: "2.Blades sharpened and adjusted to cut & trim hedges at ease.",
    features2:
      "3.Aluminium alloy handle with soft grips makes it suitable to use for continuous work.",
  },
  // chnage

  {
    id: 43,
    modelno: "PHS10",
    URLname: "Plastic-Handle-Hedge-Shear-PHS10",
    // name: "Plastic Handle Hedge Shear",
    img: "PHS10.webp",
    headname: "Plastic Handle Hedge Shear",
    sizetitle: "Size: ",
    size: "Blade Length 10.",
    // CapacityTitle: "Capacity: ",
    // capacity: "",
    material: "Alloy Steel blade.",
    handeltitle: "Handle: ",
    handel: "Plastic.",
    ApplicatonTitle: "Application: ",
    Applicaton: "Trimming of hedges & bushes.",
    cautionTitle: "Caution: ",
    caution: "Sharp blades - keep out of children.",
    maintenanceTitle: "Maintenance: ",
    maintenance: "Clean blades and swipe with oil after use.",
    URL: "Collase of Garden tools.webp",
    category: "Garden & Agro Tools",
    features: "1.Blades are duly hardened & tempered.",
    features1: "2.Blades sharpened and adjusted to cut & trim hedges at ease.",
    features2:
      "3.Aluminium alloy handle with soft grips makes it suitable to use for continuous work.",
    features3:
      "4. Lightweight - suitable to use for continuous work..                  ",
  },

  {
    id: 45,
    modelno: "THS10X",
    URLname: "Telescopic-Handle-Hedge-Shear-THS10X",
    // name: "Telescopic Hedge Shear",
    img: "THS-10X.webp",
    headname: "Telescopic Hedge Shear",
    sizetitle: "Size: ",
    size: "Blade Length 10.",
    // CapacityTitle: "Capacity: ",
    // capacity: "",
    material: "Alloy Steel blade.",
    handeltitle: "Handle: ",
    handel: " Aluminium alloy handles with soft grips.",
    ApplicatonTitle: "Application: ",
    Applicaton: "Trimming of hedges & bushes at height.",
    cautionTitle: "Caution: ",
    caution: "Sharp blades - keep out of children.",
    maintenanceTitle: "Maintenance: ",
    maintenance: "Clean blades and swipe with oil after use.",
    URL: "Collase of Garden tools.webp",
    category: "Garden & Agro Tools",
    features: "1.Blades are duly hardened & tempered.",
    features1: "2.Blades sharpened and adjusted to cut & trim hedges at ease.",
    features2: "3.Extendable aluminium alloy handles with soft grips.",
    features3: "4.Suitable to cut hedges in height by extending the handles.",
  },

  {
    id: 49,
    modelno: "AL30W",
    URLname: "Anvil-Lopper-With-Wooden-Handles-Al30W",
    // name: "Anvil Lopper With Wooden Handles",
    img: "AL30W.webp",
    headname: "Anvil Lopper With Wooden Handles",
    sizetitle: "Size: ",
    size: "Length 37.",
    CapacityTitle: "Capacity: ",
    capacity: "Cutting Capacity 40 mm.",
    material: "SK-5 STEEL.",
    handeltitle: "Handle: ",
    handel: " Aluminium alloy handles with soft grips.",
    ApplicatonTitle: "Application: ",
    Applicaton: "Cut Tree Branches.",
    cautionTitle: "Caution: ",
    caution: "Sharp blades - keep out of children.",
    maintenanceTitle: "Maintenance: ",
    maintenance: "Clean blades and swipe with oil after use.",
    URL: "Collase of Garden tools.webp",
    category: "Garden & Agro Tools",
    features:
      "1.Duly hardened & tempered and sharpened to cut dia 40mm tree branches.",
    features1: "2.Extendable aluminium alloy handles.",
    features2: "3.Light weight, heavy duty construction.",
    note: "Note: Cutting capacity depends on the type of wood species being cut and is not applicable for dried wood.",
  },
  {
    id: 50,
    modelno: "BPL37XG",
    URLname: "Telescopic-Geared-ByPass-Lopper-BPL37XG",
    // name: "Telescopic geared bypass lopper",
    img: "BPL-37XG.webp",
    headname: "Telescopic Geared ByPass Lopper",
    sizetitle: "Size: ",
    size: "Length 37.",
    CapacityTitle: "Capacity: ",
    capacity: "Cutting Capacity 40 mm.",
    material: "Blade: SK-5 STEEL.",
    handeltitle: "Handle: ",
    handel: " Aluminium alloy handles with soft grips.",
    ApplicatonTitle: "Application: ",
    Applicaton: "Cut Tree Branches.",
    cautionTitle: "Caution: ",
    caution: "Sharp blades - keep out of children.",
    maintenanceTitle: "Maintenance: ",
    maintenance: "Clean blades and swipe with oil after use.",
    URL: "Collase of Garden tools.webp",
    category: "Garden & Agro Tools",
    features:
      "1.Duly hardened & tempered and sharpened to cut dia 40mm tree branches.",
    features1: "2.Extendable aluminium alloy handles.",
    features2: "3.Light weight, heavy duty construction.",
    note: "Note: Cutting capacity depends on the type of wood species being cut and is not applicable for dried wood.",
  },

  {
    id: 58,
    modelno: "MCP31",
    URLname: "Coconut-Pruner-MCP31",
    // name: "Coconut pruner",
    img: "MCP-31.webp",
    headname: "Coconut Pruner",
    sizetitle: "Size: ",
    size: "500mm.",
    // CapacityTitle: "Capacity: ",
    // capacity: "N/A",
    material: "Highy carbon steel.",
    handeltitle: "Handle: ",
    handel: "Aluminium alloy handel with soft grips.",
    ApplicatonTitle: "Application: ",
    Applicaton: "1.Harvesting coconut and banana crops.",
    Applicaton1: "2.For tender coconut seller.",
    URL: "Collase of Garden tools.webp",
    cautionTitle: "Caution: ",
    caution: "Sharp blades - keep out of children's reach.",
    maintenanceTitle: "Maintenance: ",
    maintenance: "Clean blades and wipe with oil after use.",
    category: "Garden & Agro Tools",
    features: "1.Blades duly hardened & tempered.",
    features1: "2.Double axial ground blade for easy cutting.",
    features2: "3.Lightweight with ergonomic handle grip.",
  },
  {
    id: 59,
    modelno: "MCP31L",
    URLname: "Coconut-Pruner-With-A-Long-Handle-MCP31L",
    // name: "Coconut pruner with a long handle",
    img: "MCP31L.webp",
    headname: "Coconut Pruner With A Long Handle",
    sizetitle: "Size: ",
    size: "650mm.",
    // CapacityTitle: "Capacity: ",
    // capacity: "N/A",
    material: "Highy carbon steel.",
    handeltitle: "Handle: ",
    handel: "Aluminium alloy handel with soft grips.",
    ApplicatonTitle: "Application: ",
    Applicaton: "1.Harvesting coconut and banana crops.",
    Applicaton1: "2.For tender coconut seller.",
    cautionTitle: "Caution: ",
    caution: "Sharp blades - keep out of children's reach.",
    maintenanceTitle: "Maintenance: ",
    maintenance: "Clean blades and wipe with oil after use.",
    URL: "Collase of Garden tools.webp",
    category: "Garden & Agro Tools",
    features: "1.Blades duly hardened & tempered.",
    features1: "2.Double axial ground blade for easy cutting.",
    features2: "3.Lightweight with ergonomic handle grip.",
  },

  {
    id: 60.3,
    modelno: "HPS22",
    URLname: "Hand-Pruning-Shear-With-Sheath-HPS22",
    name: "Hand Pruning Shear With Sheath",
    img: "HPS-22.webp",
    headname: "Garden & Agro Tools",
    sizetitle: "Size: ",
    size: "10.",
    // CapacityTitle: "Capacity: ",
    // capacity: "N/A",
    material: "Highy carbon steel.",
    handeltitle: "Handle: ",
    handel: "TPE/PVC.",
    ApplicatonTitle: "Application: ",
    Applicaton: "For pruning tree branches",
    cautionTitle: "Caution: ",
    caution: "Sharp blades - keep out of children's reach.",
    maintenanceTitle: "Maintenance: ",
    maintenance: "Clean blades and wipe with oil after use.",
    URL: "Collase of Garden tools.webp",
    category: "Garden & Agro Tools",
    features: "1.Triple cut blade cuts on both push & pull strokes",
    features1: "2.Pitch 7 teeth/inch.",
  },
  {
    id: 60.4,
    modelno: "HPS23",
    URLname: "Hand-Pruning-Shear-With-Triple-Action-HPS23",
    // name: "Hand Pruning Shear With Triple Action",
    img: "HPS-23.webp",
    headname: "Hand Pruning Shear With Triple Action",
    sizetitle: "Size: ",
    size: "16.",
    // CapacityTitle: "Capacity: ",
    // capacity: "N/A",
    material: "Highy carbon steel.",
    handeltitle: "Handle: ",
    handel: "TPE/PVC.",
    ApplicatonTitle: "Application: ",
    Applicaton: "For pruning tree branches",
    cautionTitle: "Caution: ",
    caution: "Sharp blades - keep out of children's reach.",
    maintenanceTitle: "Maintenance: ",
    maintenance: "Clean blades and wipe with oil after use.",
    URL: "Collase of Garden tools.webp",
    category: "Garden & Agro Tools",
    features: "1.Triple cut blade cuts on both push & pull strokes",
    features1: "2.Pitch 7 teeth/inch.",
  },

  // Stainless Steel Tools
  {
    id: 60,
    modelno: "150B SS",
    URLname: "150B-SS",
    name: "SS Wire Stripper & Cutter",
    img: "150B-SS.webp",
    headname: "Stainless Steel Tools",
    sizetitle: "Size",
    size: "140mm.",
    // CapacityTitle: "Capacity: ",
    // capacity: "",
    material: "High Grade Stainless Steel.",
    handeltitle: "Handle: ",
    handel: "TPE.",
    // ApplicatonTitle: "Application: ",
    // Applicaton: "",
    URL: "Stainless Steel Toolsbanner.webp",
    category: "Stainless Steel Tools",
    features: "1.Heavy duty handles with finger holding ring.",
    features1: "2.Rust proof tool.",
    features2: "3.Satin finish.",
  },

  {
    id: 67,
    modelno: "015 SS",
    URLname: "015-SS",
    name: "SS Mini Nipper",
    img: "015-SS.webp",
    headname: "Stainless Steel Tools",
    sizetitle: "Size",
    size: "115 mm.",
    CapacityTitle: "Capacity: ",
    capacity: "Cut cu & all Wires upto 1.3 mm Dia",
    material: "Stainless Steel.",
    handeltitle: "Handle: ",
    handel: "TPE.",
    ApplicatonTitle: "Application: ",
    Applicaton:
      "Designed to cut soft copper and aluminium Wires up to 1.3 mm diameter.",
    URL: "Stainless Steel Toolsbanner.webp",
    category: "Stainless Steel Tools",
    features: "1.Palm type mini diagonal nipper.",
    features2: "2.Razor sharp cutting edges duly ground and polished.",
  },
  {
    id: 68,
    modelno: "011 HD",
    URLname: "011-HD",
    name: "",
    img: "011-HD.webp",
    headname: "Stainless Steel Tools",
    sizetitle: "Size",
    size: "115 mm.",
    CapacityTitle: "Capacity: ",
    capacity: "Cut: Soft Cut & AL Wires upto 2.0 mm Dia.",
    material: "High Grade Stainless Steel.",
    handeltitle: "Handle: ",
    handel: "TPE.",
    ApplicatonTitle: "Application: ",
    Applicaton:
      "Suitable to cut copper and aluminium Wires up to 2.0 mm diameter.",
    URL: "Stainless Steel Toolsbanner.webp",
    category: "Stainless Steel Tools",
    cautionTitle: "Caution: ",
    caution: "Do not cut steel wire.",
    features: "1.Most useful for jewellery industry.",
    features1: "2.Long life screw joint (scrivets).",
    features2: "3.Ergonomic and long handle grips.",
    features3: "4.Safety lock when not in use.",
  },

  {
    id: 70,
    modelno: "111 SS",
    URLname: "111-SS",
    name: "SS Palm Nipper",
    img: "111-SS.webp",
    headname: "Stainless Steel Tools",
    sizetitle: "Size",
    size: "112 mm.",
    CapacityTitle: "Capacity: ",
    capacity: "Cut: Soft Cut & AL Wires upto 1.5 mm Dia.",
    material: "Stainless Steel.",
    handeltitle: "Handle: ",
    handel: "PVC.",
    // ApplicatonTitle: "Application: ",
    // Applicaton: "",
    URL: "Stainless Steel Toolsbanner.webp",
    category: "Stainless Steel Tools",
    features: "Most useful for jewellery industry.  ",
  },
  {
    id: 71,
    modelno: "CC-100 SS",
    URLname: "CC-100-SS",
    name: "SS Cable Cutter",
    img: "CC-100-SS.webp",
    headname: "Stainless Steel Tools",
    // sizetitle: "Size",
    // size: "",
    CapacityTitle: "Capacity: ",
    capacity: "Cut 12mm Cable.",
    material: "Stainless Steel.",
    handeltitle: "Handle: ",
    handel: "TPE.",
    ApplicatonTitle: "Application: ",
    Applicaton: "SS cable cutter for 12 mm cable.",
    URL: "Stainless Steel Toolsbanner.webp",
    category: "Stainless Steel Tools",
    features: "Safety lock when not in use.",
  },
  {
    id: 72,
    modelno: "CC-200 SS",
    URLname: "CC-200-SS",
    name: "SS Cable Cutter",
    img: "CC-200-SS.webp",
    headname: "Stainless Steel Tools",
    sizetitle: "Size",
    size: "170mm.",
    CapacityTitle: "Capacity: ",
    capacity: "Cut 10mm Cable.",
    material: "Stainless Steel.",
    handeltitle: "Handle: ",
    handel: "TPE.",
    ApplicatonTitle: "Application: ",
    Applicaton: "SS cable cutter for 10 mm cable.",
    URL: "Stainless Steel Toolsbanner.webp",
    category: "Stainless Steel Tools",
    features: "Safety lock when not in use.",
  },
  {
    id: 73,
    modelno: "901 SS",
    URLname: "901 SS",
    name: "SS Multipurpose Shear, Straight Type",
    img: "901-SS.webp",
    headname: "Stainless Steel Tools",
    sizetitle: "Size",
    size: "190mm.",
    // CapacityTitle: "Capacity: ",
    // capacity: "",
    material: "Highy grade stainless Steel",
    handeltitle: "Handle: ",
    handel: "Acetate.",
    ApplicatonTitle: "Application: ",
    Applicaton:
      "Cut/shear wide variety of material like electrical Wires, cord, small tree branches, nylon ropes, cardboard, etc.",
    URL: "Stainless Steel Toolsbanner.webp",
    category: "Stainless Steel Tools",
  },
  {
    id: 74,
    modelno: "902 SS",
    URLname: "902 SS",
    name: "SS Multipurpose Shear, Straight Type",
    img: "902-SS.webp",
    headname: "Stainless Steel Tools",
    sizetitle: "Size",
    size: "180mm.",
    // CapacityTitle: "Capacity: ",
    // capacity: "",
    material: "Highy grade stainless Steel",
    // handeltitle: "Handle: ",
    // handel: "",
    ApplicatonTitle: "Application: ",
    Applicaton:
      "  Cut/shear wide variety of material like electrical Wires, cord, small tree branches, nylon ropes, cardboard, etc.",
    URL: "Stainless Steel Toolsbanner.webp",
    category: "Stainless Steel Tools",
  },

  // NIPPERS & MICRO- SHEARS
  {
    id: 75,
    modelno: "07",
    URLname: "07",
    name: "Nipper Cutter",
    img: "07.webp",
    headname: "Nippers & Micro- Shears",
    sizetitle: "Size: ",
    size: "120mm.",
    CapacityTitle: "Capacity: ",
    capacity: "Cut cu & all Conductors form 0.8mm To 1.2mm.",
    material: "Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "Dual tone 100% lead free polystyrene.",
    ApplicatonTitle: "Application: ",
    Applicaton:
      "Versatile tool for cutting copper and aluminium conductors form 0.8mm to 1.2mm.",
    category: " Nippers & Micro- Shears",
    URL: "micro shears banner.webp",
    features: "Large ergonomic handles.",
  },
  {
    id: 76,
    modelno: "07AElectra",
    URLname: "07-Electra",
    name: "Hard Wire Nipper Cutter",
    img: "07AElectra.webp",
    headname: "Nippers & Micro- Shears",
    sizetitle: "Size: ",
    size: "120mm.",
    CapacityTitle: "Capacity: ",
    capacity:
      "Cut Iron Wire upto 1.2 mm Dia  2. Cut Conductors from 1.0mm To 1.6mm Dia.",
    material: "Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "Dual tone 100% lead free polystyrene.",
    ApplicatonTitle: "Application: ",
    Applicaton:
      "Versatile tool for cutting copper and aluminium conductors form 0.8mm to 1.2mm.",
    category: " Nippers & Micro- Shears",
    URL: "micro shears banner.webp",
    features: "Large ergonomic handles.",
  },
  {
    id: 77,
    modelno: "06",
    URLname: "06",
    name: " Microshear",
    img: "06.webp",
    headname: "Nippers & Micro- Shears",
    sizetitle: "Size: ",
    size: "125mm.",
    CapacityTitle: "Capacity: ",
    capacity: "Cut Wires from 0.8mm To 1.4mm.",
    material: "Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "PVC grips.",
    ApplicatonTitle: "Application: ",
    Applicaton: "Can cut CU wires.",
    category: " Nippers & Micro- Shears",
    URL: "micro shears banner.webp",
  },
  {
    id: 78,
    modelno: "MT010",
    URLname: "MT010",
    name: "Microcutter",
    img: "MT010.webp",
    headname: "Nippers & Micro- Shears",
    sizetitle: "Size: ",
    size: "125 mm.",
    CapacityTitle: "Capacity: ",
    capacity: "Cut Wires from 0.8mm To 1.2mm.",
    material: "Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "PVC grips.",
    ApplicatonTitle: "Application: ",
    Applicaton: "Can cut CU wires.",
    category: " Nippers & Micro- Shears",
    URL: "micro shears banner.webp",
  },

  // pillers
  {
    id: 81,
    modelno: "MT-555",
    URLname: "MT-555",
    name: "Combination Plier",
    img: "MT-555.webp",
    headname: "Pilers",
    OVERALLLENGTHTitle: "Overall Length: ",
    OVERALLLENGTHT: "8.",
    CapacityTitle: "Capacity: ",
    capacity: "208mm.",
    material: "High Grade Chrome-Vanadium Steel.",
    handeltitle: "Handle: ",
    handel: "Heavy duty CA-FR sleeves.",
    ApplicatonTitle: "Application",
    Applicaton: "Can cut heavy duty electrical Wires.",
    URL: "pilerbanner.webp",
    category: "Pilers",
    features: "1.Forged out of high-grade chrome vanadium steel.",
    features1: "2.Jaws duly induction hardened.",
    features2:
      "3.Injection moulded heavy duty CA-FR sleeves with anti-slip and high hand grip to prevent slipping.",
    features4: "4.Insulated sleeves tested for 2500 volts.",
  },
  {
    id: 82,
    modelno: "MT-535",
    URLname: "MT-535",
    name: "Long Nose Piler",
    img: "MT-535.webp",
    headname: "Pilers",
    OVERALLLENGTHTitle: "Overall Length: ",
    OVERALLLENGTHT: "7.",
    CapacityTitle: "Capacity: ",
    capacity: "117mm.",
    material: "Chrome vanadium steel.",
    handeltitle: "Handle: ",
    handel: "Heavy duty CA-FR sleeves.",
    ApplicatonTitle: "Application",
    Applicaton: "Can hold and cut fine electrical wire.",
    URL: "pilerbanner.webp",
    category: "Pilers",
    features: "1.Forged out of chrome vanadium steel.",
    features1: "2.Induction hardened jaws.",
    features2:
      "3.Fine serrations diagonally opposite enabling gripping of thinnest Wires and components.",
    features3: "4.Injection moulded sleeves.",
  },
  // cable cutter
  {
    id: 83,
    modelno: "CC-200",
    URLname: "CC-200",
    name: "Cable Cutter",
    img: "CC-200.webp",
    headname: "Cable Cutter",
    sizetitle: "Size: ",
    size: "7.",
    CapacityTitle: "Capacity: ",
    capacity: "10 mm Cable.",
    material: "High Grade Alloy Steel.",
    handeltitle: "Handle: ",
    handel: "PVC.",
    URL: "cablecutterbanner.webp",
    category: "Cable Cutter",
    features: "Coaxial cable & wire cutter with lock",
  },

  {
    id: 87,
    URLname: "Torx-Drive-Bits",
    name: "Torx Drive Bits",
    img: "TORX POWER DRIVE BITS.webp",
    powerbitname: "Torx drive bits",
    headname: "Power bits",
    sizetitle: "Drive Size:",
    size: "T5 TO T40",
    category: "Power bits",
    OVERALLLENGTHTitle: "Length: ",
    OVERALLLENGTHT: "50mm, 75mm, 100mm, 150mm",
    material: "CVM Alloy Steel.",
    URL: "powerbitsbanner.webp",
    features: "1.Configuration - single ended.",
    features1: "2.Shank size - 6.35 mm A/F.",
    features2: "3.Hardened and tempered to 58-60 HRc.",
  },
  {
    id: 88,
    URLname: "Allen-Power-Drive-Bits",
    name: "Allen Power Drive Bits",
    img: "ALLEN POWER DRIVE BITS.webp",
    category: "Power bits",
    powerbitname: "Allen drive bits",
    headname: "Power bits",
    sizetitle: "Drive Size:",
    size: "A15 TO A6 & BA4, BA, BA6",
    OVERALLLENGTHTitle: "Length: ",
    OVERALLLENGTHT: "50mm, 75mm, 100mm, 150mm",
    material: "CVM Alloy Steel.",
    URL: "powerbitsbanner.webp",
    features: "1.Configuration - single ended.",
    features1: "2.Shank size - 6.35 mm A/F.",
    features2: "3.Hardened and tempered to 58-60 HRc.",
  },
  {
    id: 89,
    URLname: "Slotted-Power-Drive-Bits",
    name: "Slotted Power Drive Bits",
    img: "SLOTTED POWER DRIVE BITS.webp",
    category: "Power bits",
    powerbitname: "Allen drive bits",
    headname: "Power bits",
    sizetitle: "Drive Size:",
    size: "S3 TO S7",
    OVERALLLENGTHTitle: "Length: ",
    OVERALLLENGTHT: "50mm, 75mm, 100mm, 150mm",
    material: "CVM Alloy Steel.",
    URL: "powerbitsbanner.webp",
    features: "1.Configuration - single ended.",
    features1: "2.Shank size - 6.35 mm A/F.",
    features2: "3.Hardened and tempered to 58-60 HRc.",
  },
  // MISCELLANEOUSTOOLS
  {
    id: 90,
    URLname:
      "Multipurpose-Magnetic-Stripholder-Available-in-Model-No-MS-12-MS-18-(inches)",
    name: " Multipurpose Magnetic Stripholder Available in Model No. MS-12 (inches) and MS-18 (inches)",
    img: "MS-18.webp",
    category: "MiscellaneousTools",
    modelno: "MS-18",
    headname: "Micellaneous Tools",
    URL: "mislanioustoolbanner.webp",
    features: "1.For hanging tools, kitchen knives, scissors, key etc.",
    // features1: "2.Shank size - 6.35 mm A/F.",
    // features2: "3.Hardened and tempered to 58-60 HRc.",
  },

  {
    id: 95,
    URLname: "Keyring-with-2-in-1-Reversible-Screw-Driver-Bit.",
    name: "Keyring with 2 in 1 Reversible Screw Driver Bit.",
    img: "KR-6045-02.webp",
    category: "MiscellaneousTools",
    modelno: "KR-045",
    headname: "Micellaneous Tools",
    URL: "mislanioustoolbanner.webp",
    features:
      "1.Multipurpose key ring for holding key up to 6 piece along with a two in one reversible torx screwdriver bit for normal emergency use",
    features1: "2.Most commonly used screwdriver head T8 and t15",
    features2:
      "3.Quick Reversing and ideal for working in short small space available in other screwdriver head on demand",
  },
  {
    id: 96,
    URLname: "LAWN-EDGE-CLEANER",
    name: "LAWN EDGE CLEANER",
    img: "mts-101.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 101",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "1.Designed to neatly clean and straighten lawn edges.",
    features1: "2.Serrated pronounced edge for eﬀortless working.",
  },
  {
    id: 97,
    URLname: "SMALL-WEEDING-CULTIVATOR",
    name: "SMALL WEEDING CULTIVATOR",
    img: "mts-102.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 102",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "1. Handy tool for Weeding,Hoeing and make trenches.",
    features1:
      "2. Suitable in gardens, fields in and around bushy shrubs and plants.",
  },
  {
    id: 98,
    URLname: "CULTIVATOR-CUM-WEEDER",
    name: "CULTIVATOR CUM WEEDER",
    img: "mts-103.jpeg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 103",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "1. Sturdy tool for weeding.Hoeing and make trenches..",
    features1:
      "2. Suitable in gardens, fields in and around bushy shrubs and plants.",
  },
  {
    id: 99,
    URLname: "SOIL-AERATOR",
    name: "SOIL AERATOR",
    img: "mts-104.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 104",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "1. Designed for easy loosening and ventilation of solis.",
    features1:
      "2. Effortless use in narrow bed rows capacitating optimum supply of nutrients.",
    features2:
      "3. The back face angle of the tool head effortlessly draw through the soil.",
  },
  {
    id: 100,
    URLname: "RIDGE-PLOUGH",
    name: "RIDGE PLOUGH",
    img: "mts-105.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 105",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features:
      "1. Intricately designed curvatures for eﬀortless making of ridges, furrows and irrigation channels in ﬁelds.",
    features1: "2. Easy to use for large scale vegetable growers.",
  },
  {
    id: 101,
    URLname: "GARDEN-TILLER",
    name: "GARDEN TILLER",
    img: "mts-106.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 106",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features:
      "1. Designed for breaking up hard, compact soil into loose, broken-up soil for easy planting.",
  },
  {
    id: 102,
    URLname: "DUTCH-HOE",
    name: "DUTCH - HOE",
    img: "mts-107.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 107",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "Designed for weeding with minimal pressure",
  },
  {
    id: 103,
    URLname: "HOE-2-PRONG",
    name: "HOE - 2 PRONG",
    img: "mts-108.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 108",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "1. Two prong rake used for loosening the soil.",
    features1:
      "2. Opposite side Hoe used for hoeing-cultivating and weeding of the soil.",
    features2: "3. Suitable for pulling seed grooves.",
  },
  {
    id: 104,
    URLname: "HOE-3-PRONG",
    name: "HOE - 3 PRONG",
    img: "mts-109.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 109",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "1.Three prong rake used for loosening the soil.",
    features1:
      "2. Opposite side Hoe used for hoeing - cultivating and weeding of the soil.",
  },
  {
    id: 105,
    URLname: "HOE-3-PRONG-HD",
    name: "HOE - 3 PRONG HD",
    img: "mts-110.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 110",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features:
      "1. Heavy Duty three prong rake used for loosening the tough soil.",
    features1:
      "2. Opposite side Hoe used for hoeing - cultivating and weeding of the soil.",
  },
  {
    id: 106,
    URLname: "DRAW-HOE",
    name: "DRAW HOE",
    img: "mts-111.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 111",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features:
      "1. Designed for weeding under and between plants with its extra sharp blades.",
  },
  {
    id: 107,
    URLname: "WEED-EXTRACTOR",
    name: "WEED EXTRACTOR",
    img: "mts-112.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 112",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "1. Designed for removing deep rooted weeds from base.",
    features1:
      "2. Knife is inserted by thrusting into the soil exerting force on foot rest. The weed is pulled out without harming the plant.",
  },
  {
    id: 108,
    URLname: "SWOE-WEEDER",
    name: "SWOE WEEDER",
    img: "mts-113.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 113",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "1. Designed for weeding from roots by push and pull operation.",
  },
  {
    id: 109,
    URLname: "PUSH-PULL-WEEDER",
    name: "PUSH PULL WEEDER",
    img: "mts-114.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 114",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "1. Designed to weed light and sandy soils eﬀortlessly.",
    features1:
      "2. Two-side blades cut through weeds on the pull and push stroke below the soil.",
    features2: "3. The shaft grabs and grips weed eﬀectively.",
  },
  {
    id: 110,
    URLname: "SOIL-RAKE",
    name: "SOIL RAKE",
    img: "mts-115.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 115",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "1. 14 teeth rake for clearing large ﬁeld beds eﬀortlessly",
    features1: "2. High strength blade for heavy duty works.",
  },
  {
    id: 111,
    URLname: "BOW-RAKE",
    name: "BOW RAKE",
    img: "mts-116.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 116",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features:
      "1. 16 teeth bow rake for clearing tough large ﬁeld beds eﬀortlessly.",
    features1: "2. High strength blade for heavy duty works.",
  },
  {
    id: 112,
    URLname: "TROWEL",
    name: "TROWEL",
    img: "mts-117.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 117",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features: "1. Designed for removing soil, repot plants",
  },
  {
    id: 113,
    URLname: "FORK",
    name: "FORK",
    img: "mts-118.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS 118",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features:
      "1. Strong tines designed for breaking up soil, turning over new beds and moving plants with large roots.",
  },
  {
    id: 114,
    URLname: "MTS-HDL-15",
    name: "MTS - HDL - 15",
    img: "mts-hdl-15.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS HDL 15",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features:
      "1. .Light weight Alluminium Handle with easy Quick-fix system to attach multiple tools.",
    features1: "2. Length 15",
  },
  {
    id: 115,
    URLname: "MTS-HDL-30",
    name: "MTS - HDL - 30",
    img: "mts-hdl-30.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS HDL 30",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features:
      "1. Heavy duty light weight Aluminium Handle ﬁtted with D Grip and easy Quick-ﬁx system to attach multiple tools.",
    features1: "2. Overall Length 30",
  },
  {
    id: 116,
    URLname: "MTS-HDL-48",
    name: "MTS - HDL - 48",
    img: "mts-hdl-48.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS HDL 48",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features:
      "1. Heavy duty light weight Aluminium Handle ﬁtted with D Grip and easy Quick-ﬁx system to attach multiple tools.",
    features1: "2. Overall Length 48",
  },
  {
    id: 117,
    URLname: "MTS-HDL-60",
    name: "MTS - HDL - 60",
    img: "mts-hdl-60.jpg",
    category: "Quick-fix Multi-tool",
    modelno: "MTS HDL 60",
    headname: "Quick-fix Multi-tool",
    URL: "quickfix-multitools.jpeg",
    features:
      "1. Heavy duty light weight Aluminium Handle with easy Quick-ﬁx system to attach multiple tools.",
    features1: "2. Overall length 60",
  },
];
